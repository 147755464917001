var vm = new Vue({
    el: '#BrandLandingHero',
    data: {
        brand: "",
        modalMerchandiseSubtotal: 0,
        modalTotalItems: 0,
        modalDisplayName: '',
        modalImageUrl: '',
        modalQuantity: 0,
        modalSubtotal: 0,
        modalHeading: 'Added to Cart!',
        modalButtonText: 'Secure Checkout',
        imageWidth: {
            addToCartModal: 100
        },
        resizerParam: '?width='

    },
    methods: {
        addToCartMonetate(skuCode) {
            var viewModel = {
                SkuCode: skuCode,
                Quantity: 1,
                Alias: null
            };

            $.ajax({
                method: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(viewModel),
                dataType: "json",
                url: '/api/CartItem',
                headers: { "__RequestVerificationToken": $("input[name='__RequestVerificationToken']").val() }
            })
                .then(data => {
                    this.modalMerchandiseSubtotal = data.MerchandiseSubtotal;
                    this.modalTotalItems = data.TotalNumberOfItems;
                    this.modalDisplayName = data.AddedCartItem.DisplayName.trim();
                    this.modalImageUrl = data.AddedCartItem.ImageUrl;
                    this.modalQuantity = data.AddedCartItem.Quantity;
                    this.modalSubtotal = data.AddedCartItem.Subtotal;


                    // update cart header count
                    cei.shared.cart.updateCartHeaderQuantity(1);

                    $("#mt-add-to-cart-modal").modal('show');

                })
                .catch(error => {
                    console.error;
                });
        }

    }


});